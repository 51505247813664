import AccordionBlock from 'library/blocks/new/AccordionBlock'
import BlockOfLove from 'library/blocks/new/BlockOfLove'
import CaseStudyBlock from 'library/blocks/new/CaseStudyBlock'
import ContactCardsBlock from 'library/blocks/new/ContactCardsBlock'
import CustomersBlock from 'library/blocks/new/CustomersBlock'
import FeatureBlock from 'library/blocks/new/FeatureBlock'
import { GetStaticPropsResult } from 'next'
import Head from 'next/head'
import HeroBlock from 'library/blocks/new/HeroBlock'
import { HomePageData } from 'library/types/new/home/object'
import Layout from 'library/layout/Layout'
import QouteBlock from 'library/blocks/new/QuoteBlock'
import React from 'react'
import SpacerBlock from 'library/blocks/new/SpacerBlock'
import TradesBlock from 'library/blocks/new/TradesBlock'
import UserTypesBlock from 'library/blocks/new/UserTypesBlock/UserTypesBlock'
import homepageData from 'library/data/HomePageData.json'

type HomePageProps = {
  data: HomePageData
}

const HomePage = ({ data }: HomePageProps) => (
  <>
    <Head>
      <title>Onetrace | Fire Stopping App</title>
      <meta
        name='description'
        content='Software for complete fire protection traceability. Onetrace lets you record, manage and approve your projects all on one platform'
      />
    </Head>

    <Layout scheme='dark' type='new'>
      <SpacerBlock scheme='dark' break1640='md' break1280='md' break1024='sm' break744='sm' />
      <HeroBlock {...data['hero-blocks'].block1} />

      <TradesBlock {...data['trades-blocks'].block1} />
      <SpacerBlock scheme='dark' break1640='xl' break1280='lg' break1024='xl' break744='lg' />

      <CustomersBlock {...data['customers-blocks'].block1} />
      <SpacerBlock scheme='dark' break1640='xl' break1280='md' break1024='xl' break744='lg' />

      <SpacerBlock scheme='light' break1640='xl' break1280='lg' break1024='xl' break744='lg' />
      <FeatureBlock {...data['feature-blocks'].block1} />
      <SpacerBlock scheme='light' break1640='xl' break1280='lg' break1024='xl' break744='lg' />

      <QouteBlock {...data['quote-blocks'].block1} />

      <SpacerBlock scheme='light' break1640='xl' break1280='lg' break1024='xl' break744='lg' />
      <FeatureBlock {...data['feature-blocks'].block2} />
      <SpacerBlock scheme='light' break1640='xl' break1280='lg' break1024='xl' break744='lg' />

      <UserTypesBlock {...data['user-types-blocks'].block1} />
      <SpacerBlock scheme='light' break1640='xl' break1280='lg' break1024='xl' break744='lg' />

      <CaseStudyBlock {...data['case-study-blocks'].block1} />
      <SpacerBlock scheme='light' break1640='xl' break1280='lg' break1024='xl' break744='lg' />

      <SpacerBlock scheme='dark' break1640='xl' break1280='xl' break1024='xl' break744='lg' />
      <BlockOfLove {...data['blocks-of-love'].block1} />

      <SpacerBlock scheme='dark' break1640='xl' break1280='lg' break1024='xl' break744='lg' />
      <AccordionBlock {...data['accordion-blocks'].block1} />
      <SpacerBlock scheme='dark' break1640='xl' break1280='lg' break1024='xl' break744='lg' />

      <ContactCardsBlock {...data['contact-cards-blocks'].block1} />
      <SpacerBlock scheme='dark' break1640='xl' break1280='lg' break1024='xl' break744='lg' />
    </Layout>
  </>
)

export default HomePage

export async function getStaticProps(): Promise<GetStaticPropsResult<HomePageProps>> {
  const getData = async () => new Promise<HomePageData>((resolve) => resolve(homepageData as HomePageData))

  const data = await getData()

  return {
    props: {
      data,
    },
  }
}
